<template>
  <div>
    <ul class="el-scrollbar__view el-select-dropdown__list context-menu" ref="contextMenu">
      <li
        class="el-select-dropdown__item"
        v-for="menu in menus"
        :key="menu.key"
        @click="handleClick(menu)"
      >{{menu.name}}</li>
    </ul>
  </div>
</template>

<script>
import eventBus from "@/util/eventBus";
export default {
  data() {
    return {
      menus: [ { key: 1, name: "执行" }],
      Current:{}
    };
  },
  created() {
    this.bindEvent();
  },
  methods: {
    init() {},
    bindEvent() {
      this.$eventBus.$on("contextmenuClick1", e => {
        this.Current = e.item._cfg.id
        const menu = this.$refs.contextMenu;
        // menu.style.left = e.clientX + "px";
        // menu.style.top = e.clientY + "px";
        if(menu){
          menu.style.left = e.x+260 + "px";
        menu.style.top = e.y+45 + "px";
        menu.style.display = "block";
        }
        
      });
      this.$eventBus.$on("mousedown", () => {
        const menu = this.$refs.contextMenu;
        if(menu){
          menu.style.display = "none";
        }
        
      });
    },
    handleClick(item) {
      this.$eventBus.$emit('runt',"run")
     
      const menu = this.$refs.contextMenu;
      menu.style.display = "none";
    }
  }
};
</script>

<style scoped>
.context-menu {
  position: absolute;
  border: 1px solid #e4e7ed;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  margin: 5px 0;
  z-index: 1;
  display: none;
}

.context-menu li {
  cursor: pointer;
  font-size: 12px;
  height: 28px;
  line-height: 28px;
}
.context-menu li:hover {
  background-color: #f5f7fa;
}
</style>
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("textarea", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.value,
          expression: "value",
        },
      ],
      ref: "mycode",
      staticClass: "codesql",
      domProps: { value: _vm.value },
      on: {
        input: function ($event) {
          if ($event.target.composing) return
          _vm.value = $event.target.value
        },
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="detailpannel">
    <el-drawer
  :visible.sync="drawer"
  :with-header="false"
  :append-to-body="true"
  >

  <div>
      <div v-if="status == 'node-selected'" class="pannel" id="node_detailpannel" >
        <div class="pannel-title" style="
            color: skyblue;
            font-size: 14px;
            text-align: center;
            background-color: #fff;
          ">
          {{ $t("projectlist.btn.ParameterSetting") }}
        </div>

        <div class="block-container" style="font-size: 15px;padding: 10px;" >
          <el-row style="padding-top: 3px">{{ $t("projectlist.btn.name") }}
          </el-row>
          <el-row style="padding-top: 3px"><el-input v-model="node.name" disabled />
          </el-row>
          <el-row style="padding-top: 3px">{{ $t("projectlist.seetabs.editlabel.Chinesename") }}
          </el-row>
          <el-row style="padding-top: 3px"><el-input v-model="node.label" @change="handleChangeName" />
          </el-row>

          <div v-if="currentNode == 'obj'">
            <el-row style="padding-top: 3px">数据主题 </el-row>
            <el-row style="padding-top: 3px">
              <el-cascader :show-all-levels="false" v-model="node.sourceId" :options="sourceList" :props="{
                value: 'id',
                label: 'name',
                children: 'children',
                expandTrigger: 'hover',
              }" style="width: 100%" @change="sourceChange"></el-cascader>
            </el-row>
            <el-row style="padding-top: 3px">数据对象 </el-row>

            <el-row>
              <el-select  v-model="node.obj" style="width: 100%" @change="getobjresList" filterable>
                <el-option v-for="(item, index) in objList" :key="index" :label="item.code" :value="item.id" />
              </el-select>
            </el-row>

            <el-row style="padding-top: 3px">增量时间字段 </el-row>
            <el-row style="padding-top: 3px"><el-input v-model="node.record_time" />
            </el-row>

            <el-row style="padding-top: 3px">开始时间 </el-row>
            <el-row style="padding-top: 3px">
              <el-date-picker v-model="node.start_time" value-format="yyyy-MM-dd HH:mm:ss" type="datetime"
                placeholder="选择日期时间">
              </el-date-picker>
            </el-row>

            <el-row style="padding-top: 3px">时间格式 </el-row>
            <el-row style="padding-top: 3px"><el-input v-model="node.format_time" />
            </el-row>
          </div>





          <div v-if="currentNode == 'sql'">
            <el-row style="padding-top: 3px">数据源 </el-row>
            <el-row>
              <el-select filterable v-model="node.source"  :placeholder="$t('OfflinedataCollection.label.Databasename')
                " :error="$t('OfflinedataCollection.label.Databasename')">
                <el-option v-for="(item, index) in dbList" :key="index" :label="item.desc" :value="item.id"></el-option>
              </el-select>
            </el-row>
            <el-row style="padding-top: 3px">SQL

              <el-button type="primary" size="mini" @click="jxsql"> 解析SQL</el-button>
            </el-row>
            <el-row style="padding-top: 3px">
              <sql-editor ref="sqleditor" :value="node.sql" style="height: 300px; margin: 10px 10px"
                @changeTextarea="changeTextarea($event)" />
            </el-row>

            <el-row style="padding-top: 3px">增量时间字段 </el-row>
            <el-row style="padding-top: 3px"><el-input v-model="node.record_time" />
            </el-row>

            <el-row style="padding-top: 3px">开始时间 </el-row>
            <el-row style="padding-top: 3px">
              <el-date-picker v-model="node.start_time" value-format="yyyy-MM-dd HH:mm:ss" type="datetime"
                placeholder="选择日期时间">
              </el-date-picker>
            </el-row>

            <el-row style="padding-top: 3px">时间格式 </el-row>
            <el-row style="padding-top: 3px"><el-input v-model="node.format_time" />
            </el-row>






          </div>


          <div v-if="currentNode == 'outsource'">

            <el-row style="padding-top: 3px">目标信息-数据源 </el-row>
            <el-row>
              <el-select filterable @change="dbChangeFn1" v-model="node.writerDatasourceId" :placeholder="$t('OfflinedataCollection.label.Databasename')
                " :error="$t('OfflinedataCollection.label.Databasename')">
                <el-option v-for="(item, index) in dbList" :key="index" :label="item.desc" :value="item.name"></el-option>
              </el-select>
            </el-row>


            <el-row style="padding-top: 3px">目标信息-表选择 </el-row>
            <el-row>
              <el-select filterable v-model="node.writerTable" @change="getColumnFn1" :placeholder="$t('OfflinedataCollection.label.Datatablename')
                " :error="$t('OfflinedataCollection.label.Datatablename')">
                <el-option v-for="(item, index) in biaoList1" :key="index" :label="item.table_name"
                  :value="item.table_name"></el-option>
              </el-select>
            </el-row>
            <el-row style="padding-top: 3px">对应关系
              <el-button type="primary" size="mini" @click="getdyList"> 获取列表</el-button></el-row>
            <el-row style="padding-top: 3px">




              <el-table ref="step2Table" :data="colmnList" border size="small" style="width: 100%">
                <el-table-column :label="$t('cip.dc.Token.field.Fieldname')" prop="name">
                </el-table-column>

                <el-table-column label="字段注释" prop="comment">
                </el-table-column>

                <el-table-column label="对应关系">
                  <template slot-scope="scope">
                    <el-select v-model="scope.row.sourceColumn" clearable filterable >
                      <el-option :label="item.name" :value="item.name" v-for="(item, index) in step0SaveChecked"
                        :key="index">
                      </el-option>
                    </el-select>
                  </template>
                </el-table-column>
              </el-table>


            </el-row>

          </div>


          <div v-if="currentNode == 'border'">





            <el-row style="padding-top: 3px">最小值 </el-row>
            <el-row style="padding-top: 3px"><el-input v-model="node.min" />
            </el-row>



            <el-row style="padding-top: 3px">最大值 </el-row>
            <el-row style="padding-top: 3px"><el-input v-model="node.max" />
            </el-row>






          </div>






        </div>
      </div>
      <div v-if="status === 'canvas-selected'" class="pannel" id="canvas_detailpannel">
        <!-- <div class="pannel-title">{{ $t("projectlist.btn.canvas") }}</div>
        <div class="block-container">
          <el-checkbox v-model="showGrid" @change="changeGridState">{{
            $t("projectlist.btn.Gridalignment")
          }}</el-checkbox>
        </div> -->
      </div>


      <!-- <Minimap /> -->
    </div>

</el-drawer>


  </div>
</template>

<script>
import eventBus from "@/util/eventBus";
import SqlEditor from "@/components/SqlEditor";
import Grid from "@antv/g6/build/grid";
import Minimap from "../Minimap";

import {
  getDataTree,
  ObjectList,
  ObjectList2,
  getDataOBJXQ2,
  zjgetSqlParse,
  getOutputResultSetList,
  dataObjectSF,
} from "@/api/dataAcquisition/index";
import {
  dataXBuildJson,
  dbListAll,
  getAiModelList,
  getColumn,
  getColumnsByQuerySql,
  syncDb,
  SaveOffline,
  relationEnum,
  UpdateOffline,
} from "@/api/dataAcquisition/offline";
export default {
  components: { SqlEditor, Minimap },
  data() {
    return {
      drawer: false,
      resultSetList:[],
      jobList:[],
      dbList: [],//数据源下拉
      biaoList: [],//表下拉
      colmnList: [],//表数据
      biaoList1: [],//目标表下拉
      sourceList: [], //数据主题下拉
      step0SaveChecked: [],
      readerPareList: [],
      objList: [], //数据对象
      status: "canvas-selected",
      showGrid: false,
      page: {},
      graph: {},
      item: {},
      node: { center: "" },
      grid: null,
      currentNode: "",
    };
  },
  created() {
    this.init();
    this.bindEvent();
    this.MgetDataTree();
    this.getDbTableListFn();
  },
  mounted() { },
  methods: {
    //数据源下拉
    getDbTableListFn() {
      dbListAll({}).then((res) => {
        this.dbList = res.data.data;
      });
    },
    //选择数据源获取表
    dbChangeFn(value) {
      let source = this.dbList.find((item) => item.name == value);
      let obj = {
        host_id: source.name,
        dbType: source.dbtype,
        id: source.db_id,
      };
      this.getAiModelListFn(obj);
    },

    //根据数据库获取表
    getAiModelListFn(obj) {
      getAiModelList(obj).then((res) => {
        this.biaoList = res.data.data;
      });
    },




    dbChangeFn1(value) {
      let source = this.dbList.find((item) => item.name == value);
      let obj = {
        host_id: source.name,
        dbType: source.dbtype,
        id: source.db_id,
      };
      getAiModelList(obj).then((res) => {
        this.biaoList1 = res.data.data;
      });
      this.node.sourceId = obj.id
    },


    getColumnFn1(value) {
      let source = this.biaoList1.find((item) => item.table_name == value);
      let obj = {
        dbtype: this.node.writerDatasourceId,
        host_id: source.host_id,
        name: source.table_name,
      };
      console.log(source,"777777777777777777777777777777");
      this.node.tableId = source.tableId
      this.dbList.forEach((element) => {
        if (element.name == this.node.writerDatasourceId) {
          obj.dbtype = element.dbtype;
        }
      });
      getColumn(obj).then((res) => {
        this.step0SaveChecked = res.data.data;

      });
    },





    jxsql() {
      zjgetSqlParse({ querySql: this.node.sql }).then(res => {
        this.resultSetList = [{}]
       let aa = res.data.data
       aa.forEach(element => {
        this.resultSetList[0][element.columnName] = 10
       });
       this.node.resultSetList = this.resultSetList
      })
    },



    init() { },
    bindEvent() {
      let self = this;

      eventBus.$on("afterAddPage", (page) => {
        self.page = page;
        self.graph = self.page.graph;

        eventBus.$on("nodeselectchange", (item) => {
          if (item.select === true && item.target.getType() === "node") {
            self.status = "node-selected";
            this.drawer = true
            self.item = item.target;
            self.node = item.target.getModel();
            console.log(self.node, "这是一个组件信息");

            this.currentNode = self.node.nodeType;
            //回显数据对象
            if (self.node.nodeType == 'obj' && self.node.obj && self.node.sourceId) {
              let parms = {
                modelId: self.node.sourceId[1],
                query: { current: 1, size: 100 },
              };

              ObjectList(parms).then((res) => {
                this.objList = res.data.data.records;
              });
            }

            //回显数据输出
            if (self.node.nodeType == 'outsource' && self.node.writerDatasourceId) {
              let source = this.dbList.find((item) => item.name == self.node.writerDatasourceId);
              let obj = {
                host_id: source.name,
                dbType: source.dbtype,
                id: source.db_id,
              };
              getAiModelList(obj).then((res) => {
                this.biaoList1 = res.data.data;
                if (self.node.writerTable) {
                  let source = this.biaoList1.find((item) => item.table_name == self.node.writerTable);
                  let obj = {
                    dbtype: this.node.writerDatasourceId,
                    host_id: source.host_id,
                    name: source.table_name,
                  };
                  this.dbList.forEach((element) => {
                    if (element.name == this.node.writerDatasourceId) {
                      obj.dbtype = element.dbtype;
                    }
                  });
                  getColumn(obj).then((res) => {
                    this.step0SaveChecked = res.data.data;

                  });
                }



              });
                if(this.node.colmnList){
                  this.colmnList=this.node.colmnList
                }









            }


          } else {
            // self.status = "node-selected";
            // self.item = null;
            // console.log("选中了2", self.node);
            // self.node = null;
          }


        });
      });
    },
    handleChangeName(e) {
      const model = {
        label: e,
      };
      console.log("name");
      this.graph.update(this.item, model);
    },
    changeGridState(value) {
      if (value) {
        this.grid = new Grid();
        this.graph.addPlugin(this.grid);
      } else {
        this.graph.removePlugin(this.grid);
      }
    },

    // 数据主题下拉
    MgetDataTree() {
      getDataTree({ object: 0 }).then((res) => {
        if (res.status == 200) {
          this.sourceList = res.data.data;
          this.sourceList.forEach((element) => {
            element.children.forEach((element2) => {
              if (element2.children) {
                element2.children = undefined;
              }
            });
          });
        }
      });
    },
    //获取数据对象
    sourceChange(value) {
      let parms = {
        modelId: value[1],
        query: { current: 1, size: 100 },
      };

      ObjectList(parms).then((res) => {
        this.objList = res.data.data.records;
      });
    },

   //通过数据对象id获取保存字段列表
    getobjresList(e){
      console.log(e);
      dataObjectSF({id:e}).then(res=>{
       this.resultSetList = [{}]
       let aa = res.data.data
       aa.forEach(element => {
        this.resultSetList[0][element] = 10
       });

       this.node.resultSetList = this.resultSetList
      })
    },

    changeTextarea(val) {
      this.node.sql = val;
    },
    findnode(e){
      let aa = {}
     let g6 = this.graph.save()
      g6.nodes.forEach(element => {
        if(e==element.id){
           aa = element
        }
      });
      return aa
    },

    getdyList(){
      let aa = JSON.parse(JSON.stringify(this.graph.save()));

      //添加所有节点
      this.jobList = []
      aa.nodes.forEach(element => {
        this.jobList.push({ id: element.id, code: element.nodeType, type: element.typeNum, jobRelation: { upJob: [], downJob: [] },param:element.nodeType=="border" ?{border:{max:element.max,min:element.min}} :undefined, })
      });
      //添加节点连线关系
      if (aa.edges) {
        this.jobList.forEach(element => {
          aa.edges.forEach(element2 => {
            let aa =  this.findnode(element2.sourceId)
            if (element.id == element2.targetId) {
              element.jobRelation.upJob.push({ id: element2.sourceId, code: aa.nodeType, type: aa.typeNum, })
            }
            if (element.id == element2.sourceId) {
            let aa =  this.findnode(element2.targetId)
              element.jobRelation.downJob.push({ id: element2.targetId, code: aa.nodeType, type: aa.typeNum,    })

            }

          });
        });


      }
       let parms = {
        resultSetList:this.resultSetList,
        jobList:this.jobList
       }
       if(parms.resultSetList.length==0){
        aa.nodes.forEach(element => {
          if(element.nodeType=="obj" || element.nodeType=="sql" ){
            if(element.resultSetList){
              parms.resultSetList = element.resultSetList
            }

          }
         console.log(element,"asdasdasdasdasdasd");

      });
       }
      getOutputResultSetList(parms).then(res=>{
         this.node.colmnList = res.data.data
         this.colmnList = res.data.data
         console.log(this.node.colmnList);
      })
  }


  },
};
</script>

<style scoped>
.detailpannel {
  height: 100%;
  overflow-y: scroll;
  position: absolute;
  right: 0px;
  z-index: 2;
  background: #f7f9fb;
  width: 0px;
  border-left: 1px solid #e6e9ed;
}

.detailpannel .block-container {
  padding: 16px 8px;
}

.block-container .el-col {
  height: 28px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.pannel-title {
  height: 32px;
  border-top: 1px solid #dce3e8;
  border-bottom: 1px solid #dce3e8;
  background: #ebeef2;
  color: #000;
  line-height: 28px;
  padding-left: 12px;
}

::v-deep .el-input__inner {
  height: 30px;
}
</style>


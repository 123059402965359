<template>
  <div>


    <el-collapse v-model="activeNames" @change="handleChange">
      <el-collapse-item :title="$t('omit.dataInput')" name="1">
        <template slot="title">
         <span style="margin-left: 40px;"> {{ $t('omit.dataInput') }}</span> 
    </template>
        <ul>
          <li
            v-for="(item, index) in list"
            :key="index"
            class="getItem"
            :data-shape="item.shape"
            :data-type="item.type"
            :data-size="item.size"
            draggable
            @dragstart="handleDragstart"
            @dragend="handleDragEnd($event, item)"
            style="text-align: center; "
          >
           
            
            <span style="font-size: 14px;">{{ item.name }}</span>
          
           
          </li>
        </ul>
      </el-collapse-item>
      <el-collapse-item :title="$t('omit.dataOutput')" name="2">
        <template slot="title">
         <span style="margin-left: 40px;"> {{ $t('omit.dataOutput') }}</span> 
    </template>
        <ul>
          <li
            v-for="(item, index) in list2"
            :key="index"
            class="getItem"
            :data-shape="item.shape"
            :data-type="item.type"
            :data-size="item.size"
            draggable
            @dragstart="handleDragstart"
            @dragend="handleDragEnd($event, item)"
            style="text-align: center;"
          >
          
            
            <span style="font-size: 14px;">{{ item.name }}</span>
           
          </li>
        </ul>
      </el-collapse-item>
      <!-- <el-collapse-item :title="$t('omit.Computing')" name="3">
        <template slot="title">
         <span style="margin-left: 40px;"> {{ $t('omit.Computing') }}</span> 
    </template>
        <ul>
          <li
            v-for="(item, index) in list3"
            :key="index"
            class="getItem"
            :data-shape="item.shape"
            :data-type="item.type"
            :data-size="item.size"
            draggable
            @dragstart="handleDragstart"
            @dragend="handleDragEnd($event, item)"
            style="text-align: center;"
          >
         
            
            <span style="font-size: 14px;">{{ item.name }}</span>
          
          </li>
        </ul>
      </el-collapse-item> -->
      <el-collapse-item title="数据编码" name="4">
         <template slot="title">
         <span style="margin-left: 40px;"> 数据编码</span> 
    </template>
        <ul>
          <li
            v-for="(item, index) in list4"
            :key="index"
            class="getItem"
            :data-shape="item.shape"
            :data-type="item.type"
            :data-size="item.size"
            draggable
            @dragstart="handleDragstart"
            @dragend="handleDragEnd($event, item)"
            style="text-align: center;"
          >
            
            <span style="font-size: 14px;">{{ item.name }}</span>
          </li>
        </ul>
      </el-collapse-item>
      <el-collapse-item title="量纲化处理" name="5">
        <template slot="title">
         <span style="margin-left: 40px;"> 量纲化处理</span> 
    </template>
        <ul>
          <li
            v-for="(item, index) in list5"
            :key="index"
            class="getItem"
            :data-shape="item.shape"
            :data-type="item.type"
            :data-size="item.size"
            draggable
            @dragstart="handleDragstart"
            @dragend="handleDragEnd($event, item)"
            style="text-align: center; "
          >
            
            <span style="font-size: 14px;">{{ item.name }}</span>
          </li>
        </ul>
      </el-collapse-item>


      <el-collapse-item title="数值处理" name="6">
        <template slot="title">
         <span style="margin-left: 40px;text-align: center;"> 数值处理</span> 
    </template>
        <ul>
          <li
            v-for="(item, index) in list6"
            :key="index"
            class="getItem"
            :data-shape="item.shape"
            :data-type="item.type"
            :data-size="item.size"
            draggable
            @dragstart="handleDragstart"
            @dragend="handleDragEnd($event, item)"
            style="text-align: center; "
          >
           
            <span style="font-size: 14px;">{{ item.name }}</span>
          </li>
        </ul>
      </el-collapse-item>

      <el-collapse-item title="时间处理" name="7">
        <template slot="title">
         <span style="margin-left: 40px;text-align: center;"> 时间处理</span> 
    </template>
        <ul>
          <li
            v-for="(item, index) in list7"
            :key="index"
            class="getItem"
            :data-shape="item.shape"
            :data-type="item.type"
            :data-size="item.size"
            draggable
            @dragstart="handleDragstart"
            @dragend="handleDragEnd($event, item)"
            style="text-align: center; "
          >
            
            <span style="font-size: 14px;">{{ item.name }}</span>
          </li>
        </ul>
      </el-collapse-item>


      <el-collapse-item title="异常值处理" name="8">
        <template slot="title">
         <span style="margin-left: 40px;text-align: center;"> 异常值处理</span> 
    </template>
        <ul>
          <li
            v-for="(item, index) in list8"
            :key="index"
            class="getItem"
            :data-shape="item.shape"
            :data-type="item.type"
            :data-size="item.size"
            draggable
            @dragstart="handleDragstart"
            @dragend="handleDragEnd($event, item)"
            style="text-align: center; "
          >
            
            <span style="font-size: 14px;">{{ item.name }}</span>
          </li>
        </ul>
      </el-collapse-item>

      <el-collapse-item title="自定义处理" name="9">
        <template slot="title">
         <span style="margin-left: 40px;text-align: center;"> 自定义处理</span> 
    </template>
        <ul>
          <li
            v-for="(item, index) in list9"
            :key="index"
            class="getItem"
            :data-shape="item.shape"
            :data-type="item.type"
            :data-size="item.size"
            draggable
            @dragstart="handleDragstart"
            @dragend="handleDragEnd($event, item)"
            style="text-align: center; "
          >
            
            <span style="font-size: 14px;">{{ item.name }}</span>
          </li>
        </ul>
      </el-collapse-item>




      
    </el-collapse>
  </div>
</template>

<script>
import eventBus from "@/util/eventBus";
// import okSvg from "@/assets/icons/ok.svg";
export default {
  data() {
    return {
      data1: [
        {
          name: "",
          children: [],
        },
      ],
      activeNames: ["1", "2", "3", "4", "5", "6", "7", "8","9"],
      page: null,
      command: null,
      offsetX: 0,
      offsetY: 0,
     
      list: [
      {
          id2: 23,
          name: "数据表",
          label: "数据表",
          suffix: "数据表",
          size: "170*34",
          type: "node",
          nodeType:"sql",
          typeNum:1,
          ipttype:2,
          center: "",
          x: 0,
          y: 0,
          shape: "customNode",
          color: "#1890ff",
          // image:"el-icon-finished",
            
          inPoints: [[0, 0.5]],
          outPoints: [[1, 0.5]],
        },
        {
          id2: 21,
          name: this.$t('omit.dataobj'),
          label: this.$t('omit.dataobj'),
          suffix: this.$t('omit.dataobj'),
          size: "170*34",
          type: "node",
          nodeType:"obj",
          typeNum:1,
          ipttype:1,
          center: "",
          x: 0,
          y: 0,
          shape: "customNode",
          color: "#1890ff",
          // image:"el-icon-s-order",
            
          inPoints: [[0, 0.5]],
          outPoints: [[1, 0.5]],
        },

       

       

       
      ],
      list2: [
        {
          // this.$t('omit.dataOutput')+"-"+
          id2: 31,
          name:this.$t('omit.dataSheet'),
          label: this.$t('omit.dataSheet'),
          suffix: this.$t('omit.dataSheet'),
          size: "170*34",
          type: "node",
          typeNum:2,
          nodeType:"outsource",
          center: "",
          x: 0,
          y: 0,
          shape: "customNode",
          color: "#1890ff",
          // image:"el-icon-document-copy",
          inPoints: [[0, 0.5]],
          outPoints: [[1, 0.5]],
        },

        {
          id2: 21,
          name: this.$t('omit.dataobj'),
          label: this.$t('omit.dataobj'),
          suffix: this.$t('omit.dataobj'),
          size: "170*34",
          type: "node",
          nodeType:"obj",
          typeNum:1,
          ipttype:1,
          center: "",
          x: 0,
          y: 0,
          shape: "customNode",
          color: "#1890ff",
         
          inPoints: [[0, 0.5]],
          outPoints: [[1, 0.5]],
        },

       
      ],

      list3: [
        // {
        //   id2: 41,
        //   name: this.$t('omit.variance'),
        //   label: this.$t('omit.variance'),
        //   suffix: this.$t('omit.variance'),
        //   size: "170*34",
        //   type: "node",
        //   typeNum:0,
        //   nodeType:"variance",
        //   center: "",
        //   x: 0,
        //   y: 0,
        //   shape: "customNode",
        //   color: "#1890ff",
        //   // image:"el-icon-cpu",
          
        //   inPoints: [[0, 0.5]],
        //   outPoints: [[1, 0.5]],
        // },
        {
          id2: 42,
          // name: this.$t('omit.Boundarylimitation'),
          // label: this.$t('omit.Boundarylimitation'),
          // suffix: this.$t('omit.Boundarylimitation'),

          name: '区间化',
          label: '区间化',
          suffix: '区间化',
          size: "170*34",
          type: "node",
          typeNum:0,
          nodeType:"border",
          center: "",
          x: 0,
          y: 0,
          shape: "customNode",
          color: "#1890ff",
          // image:"el-icon-s-data",
          
          inPoints: [[0, 0.5]],
          outPoints: [[1, 0.5]],
        },

        
      ],



     //数据编码
      list4: [
        {
          id2: 51,
          name: '数字编码',
          label: '数字编码',
          suffix: '数字编码',
          size: "170*34",
          type: "node",
          typeNum:0,
          nodeType:"numcode",
          center: "",
          x: 0,
          y: 0,
          shape: "customNode",
          color: "#1890ff",
          // image:"el-icon-cpu",
          
          inPoints: [[0, 0.5]],
          outPoints: [[1, 0.5]],
        },
        {
          id2: 52,
          name: '范围编码',
          label: '范围编码',
          suffix: '范围编码',
          size: "170*34",
          type: "node",
          typeNum:0,
          nodeType:"rangecode",
          center: "",
          x: 0,
          y: 0,
          shape: "customNode",
          color: "#1890ff",
          // image:"el-icon-s-data",
          
          inPoints: [[0, 0.5]],
          outPoints: [[1, 0.5]],
        },

        
      ],


      //数据编码
      list5: [
        {
          id2: 61,
          name: '标准化',
          label: '标准化',
          suffix: '标准化',
          size: "170*34",
          type: "node",
          typeNum:0,
          nodeType:"standardization",
          center: "",
          x: 0,
          y: 0,
          shape: "customNode",
          color: "#1890ff",
          // image:"el-icon-cpu",
          
          inPoints: [[0, 0.5]],
          outPoints: [[1, 0.5]],
        },
        {
          id2: 62,
          name: '中心化',
          label: '中心化',
          suffix: '中心化',
          size: "170*34",
          type: "node",
          typeNum:0,
          nodeType:"rangecode",
          center: "",
          x: 0,
          y: 0,
          shape: "customNode",
          color: "#1890ff",
          // image:"el-icon-s-data",
          
          inPoints: [[0, 0.5]],
          outPoints: [[1, 0.5]],
        },

        {
          id2: 63,
          name: '归一化',
          label: '归一化',
          suffix: '归一化',
          size: "170*34",
          type: "node",
          typeNum:0,
          nodeType:"normalization",
          center: "",
          x: 0,
          y: 0,
          shape: "customNode",
          color: "#1890ff",
          // image:"el-icon-s-data",
          
          inPoints: [[0, 0.5]],
          outPoints: [[1, 0.5]],
        },

        {
          id2: 64,
          name: '均值化',
          label: '均值化',
          suffix: '均值化',
          size: "170*34",
          type: "node",
          typeNum:0,
          nodeType:"Equalization",
          center: "",
          x: 0,
          y: 0,
          shape: "customNode",
          color: "#1890ff",
          // image:"el-icon-s-data",
          
          inPoints: [[0, 0.5]],
          outPoints: [[1, 0.5]],
        },

        {
          id2: 65,
          name: '正向化',
          label: '正向化',
          suffix: '正向化',
          size: "170*34",
          type: "node",
          typeNum:0,
          nodeType:"Normalization",
          center: "",
          x: 0,
          y: 0,
          shape: "customNode",
          color: "#1890ff",
          // image:"el-icon-s-data",
          
          inPoints: [[0, 0.5]],
          outPoints: [[1, 0.5]],
        },
        {
          id2: 66,
          name: '逆向化',
          label: '逆向化',
          suffix: '逆向化',
          size: "170*34",
          type: "node",
          typeNum:0,
          nodeType:"Reversion",
          center: "",
          x: 0,
          y: 0,
          shape: "customNode",
          color: "#1890ff",
          // image:"el-icon-s-data",
          
          inPoints: [[0, 0.5]],
          outPoints: [[1, 0.5]],
        },
        {
          id2: 67,
          name: '区间化',
          label: '区间化',
          suffix: '区间化',
          size: "170*34",
          type: "node",
          typeNum:0,
          nodeType:"Intervalization",
          center: "",
          x: 0,
          y: 0,
          shape: "customNode",
          color: "#1890ff",
          // image:"el-icon-s-data",
          
          inPoints: [[0, 0.5]],
          outPoints: [[1, 0.5]],
        },
        {
          id2: 68,
          name: '初值化',
          label: '初值化',
          suffix: '初值化',
          size: "170*34",
          type: "node",
          typeNum:0,
          nodeType:"initialization",
          center: "",
          x: 0,
          y: 0,
          shape: "customNode",
          color: "#1890ff",
          // image:"el-icon-s-data",
          
          inPoints: [[0, 0.5]],
          outPoints: [[1, 0.5]],
        },
        {
          id2: 69,
          name: '最小值化',
          label: '最小值化',
          suffix: '最小值化',
          size: "170*34",
          type: "node",
          typeNum:0,
          nodeType:"Minimization",
          center: "",
          x: 0,
          y: 0,
          shape: "customNode",
          color: "#1890ff",
          // image:"el-icon-s-data",
          
          inPoints: [[0, 0.5]],
          outPoints: [[1, 0.5]],
        },
        {
          id2: 611,
          name: '最大值化',
          label: '最大值化',
          suffix: '最大值化',
          size: "170*34",
          type: "node",
          typeNum:0,
          nodeType:"Maximize",
          center: "",
          x: 0,
          y: 0,
          shape: "customNode",
          color: "#1890ff",
          // image:"el-icon-s-data",
          
          inPoints: [[0, 0.5]],
          outPoints: [[1, 0.5]],
        },
        {
          id2: 612,
          name: '求和归一化',
          label: '求和归一化',
          suffix: '求和归一化',
          size: "170*34",
          type: "node",
          typeNum:0,
          nodeType:"Sumnormalization",
          center: "",
          x: 0,
          y: 0,
          shape: "customNode",
          color: "#1890ff",
          // image:"el-icon-s-data",
          
          inPoints: [[0, 0.5]],
          outPoints: [[1, 0.5]],
        },
        {
          id2: 613,
          name: '平方和归一化',
          label: '平方和归一化',
          suffix: '平方和归一化',
          size: "170*34",
          type: "node",
          typeNum:0,
          nodeType:"NormalizationSumSquares",
          center: "",
          x: 0,
          y: 0,
          shape: "customNode",
          color: "#1890ff",
          // image:"el-icon-s-data",
          
          inPoints: [[0, 0.5]],
          outPoints: [[1, 0.5]],
        },
        
       

        
      ],


       //数值处理
       list6: [
        {
          id2: 71,
          name: '平均值',
          label: '平均值',
          suffix: '平均值',
          size: "170*34",
          type: "node",
          typeNum:0,
          nodeType:"averageValue",
          center: "",
          x: 0,
          y: 0,
          shape: "customNode",
          color: "#1890ff",
          // image:"el-icon-cpu",
          
          inPoints: [[0, 0.5]],
          outPoints: [[1, 0.5]],
        },
        {
          id2: 72,
          name: '求和',
          label: '求和',
          suffix: '求和',
          size: "170*34",
          type: "node",
          typeNum:0,
          nodeType:"summation",
          center: "",
          x: 0,
          y: 0,
          shape: "customNode",
          color: "#1890ff",
          // image:"el-icon-s-data",
          
          inPoints: [[0, 0.5]],
          outPoints: [[1, 0.5]],
        },

        {
          id2: 73,
          name: '中位数',
          label: '中位数',
          suffix: '中位数',
          size: "170*34",
          type: "node",
          typeNum:0,
          nodeType:"median",
          center: "",
          x: 0,
          y: 0,
          shape: "customNode",
          color: "#1890ff",
          // image:"el-icon-s-data",
          
          inPoints: [[0, 0.5]],
          outPoints: [[1, 0.5]],
        },

        {
          id2: 74,
          name: '乘积',
          label: '乘积',
          suffix: '乘积',
          size: "170*34",
          type: "node",
          typeNum:0,
          nodeType:"product",
          center: "",
          x: 0,
          y: 0,
          shape: "customNode",
          color: "#1890ff",
          // image:"el-icon-s-data",
          
          inPoints: [[0, 0.5]],
          outPoints: [[1, 0.5]],
        },

        {
          id2: 75,
          name: '平方',
          label: '平方',
          suffix: '平方',
          size: "170*34",
          type: "node",
          typeNum:0,
          nodeType:"square",
          center: "",
          x: 0,
          y: 0,
          shape: "customNode",
          color: "#1890ff",
          // image:"el-icon-s-data",
          
          inPoints: [[0, 0.5]],
          outPoints: [[1, 0.5]],
        },
        {
          id2: 76,
          name: '根号',
          label: '根号',
          suffix: '根号',
          size: "170*34",
          type: "node",
          typeNum:0,
          nodeType:"radicalSign",
          center: "",
          x: 0,
          y: 0,
          shape: "customNode",
          color: "#1890ff",
          // image:"el-icon-s-data",
          
          inPoints: [[0, 0.5]],
          outPoints: [[1, 0.5]],
        },
        {
          id2: 77,
          name: '绝对值',
          label: '绝对值',
          suffix: '绝对值',
          size: "170*34",
          type: "node",
          typeNum:0,
          nodeType:"absoluteValue",
          center: "",
          x: 0,
          y: 0,
          shape: "customNode",
          color: "#1890ff",
          // image:"el-icon-s-data",
          
          inPoints: [[0, 0.5]],
          outPoints: [[1, 0.5]],
        },
        {
          id2: 78,
          name: '倒数',
          label: '倒数',
          suffix: '倒数',
          size: "170*34",
          type: "node",
          typeNum:0,
          nodeType:"countBackwards",
          center: "",
          x: 0,
          y: 0,
          shape: "customNode",
          color: "#1890ff",
          // image:"el-icon-s-data",
          
          inPoints: [[0, 0.5]],
          outPoints: [[1, 0.5]],
        },
        {
          id2: 79,
          name: '相反数',
          label: '相反数',
          suffix: '相反数',
          size: "170*34",
          type: "node",
          typeNum:0,
          nodeType:"oppositeNumber",
          center: "",
          x: 0,
          y: 0,
          shape: "customNode",
          color: "#1890ff",
          // image:"el-icon-s-data",
          
          inPoints: [[0, 0.5]],
          outPoints: [[1, 0.5]],
        },
        {
          id2: 710,
          name: '区间化',
          label: '区间化',
          suffix: '区间化',
          size: "170*34",
          type: "node",
          typeNum:0,
          nodeType:"Intervalization",
          center: "",
          x: 0,
          y: 0,
          shape: "customNode",
          color: "#1890ff",
          // image:"el-icon-s-data",
          
          inPoints: [[0, 0.5]],
          outPoints: [[1, 0.5]],
        },
        
        
       

        
      ],


    //时间处理
      list7: [
        {
          id2: 81,
          name: '日期截取',
          label: '日期截取',
          suffix: '日期截取',
          size: "170*34",
          type: "node",
          typeNum:0,
          nodeType:"Dateinterception",
          center: "",
          x: 0,
          y: 0,
          shape: "customNode",
          color: "#1890ff",
          // image:"el-icon-cpu",
          
          inPoints: [[0, 0.5]],
          outPoints: [[1, 0.5]],
        },
        {
          id2: 82,
          name: '日期相减',
          label: '日期相减',
          suffix: '日期相减',
          size: "170*34",
          type: "node",
          typeNum:0,
          nodeType:"border",
          center: "",
          x: 0,
          y: 0,
          shape: "customNode",
          color: "#1890ff",
          // image:"el-icon-s-data",
          
          inPoints: [[0, 0.5]],
          outPoints: [[1, 0.5]],
        },

        
      ],


      //时间处理
      list8: [
        {
          id2: 91,
          name: '无效样本',
          label: '无效样本',
          suffix: '无效样本',
          size: "170*34",
          type: "node",
          typeNum:0,
          nodeType:"InvalidSample",
          center: "",
          x: 0,
          y: 0,
          shape: "customNode",
          color: "#1890ff",
          // image:"el-icon-cpu",
          
          inPoints: [[0, 0.5]],
          outPoints: [[1, 0.5]],
        },
        {
          id2: 92,
          name: '异常值',
          label: '异常值',
          suffix: '异常值',
          size: "170*34",
          type: "node",
          typeNum:0,
          nodeType:"outlier",
          center: "",
          x: 0,
          y: 0,
          shape: "customNode",
          color: "#1890ff",
          // image:"el-icon-s-data",
          
          inPoints: [[0, 0.5]],
          outPoints: [[1, 0.5]],
        },

        
      ],


      //时间处理
      list9: [
        {
          id2: 101,
          name: 'python',
          label: 'python',
          suffix: 'python',
          size: "170*34",
          type: "node",
          typeNum:0,
          nodeType:"CusrPython",
          center: "",
          x: 0,
          y: 0,
          shape: "customNode",
          color: "#1890ff",
          // image:"el-icon-cpu",
          
          inPoints: [[0, 0.5]],
          outPoints: [[1, 0.5]],
        },
        

        
      ],










     

      defaultProps: {
        children: "children",
        label: "name",
      },
    };
  },
  created() {
    this.bindEvent();
  },
  mounted() {
    this.$eventBus.$on("eventNameq2", (res) => {
      console.log("item页面");
      this.data1[0].name = res.data.data.tempname;
      this.data1[0].children = res.data.data.children;
    });
  },
  methods: {
    handleDragstart(e) {
      this.offsetX = e.offsetX;
      this.offsetY = e.offsetY;
    },
    handleDragEnd(e, item) {
      let data = {};
      Object.assign(data, item);
      data.offsetX = this.offsetX;
      data.offsetY = this.offsetY;
      if (this.page) {
        const graph = this.page.graph;
        // const size = e.target.dataset.size.split("*");
        const xy = graph.getPointByClient(e.x, e.y);
        data.x = xy.x;
        data.y = xy.y;
        data.size = item.size.split("*");
        data.type = "node";
        this.command.executeCommand("add", [data]);
      }
    },
    bindEvent() {
      eventBus.$on("afterAddPage", (page) => {
        this.page = page;
        this.command = page.command;
      });
    },
  },
};
</script>

<style scoped>
.itempannel {
  height: 100%;
  position: absolute;
  left: 0px;
  z-index: 2;
  background: #f7f9fb;
  width: 200px;
  padding-top: 8px;
  border-right: 1px solid #e6e9ed;
}
.itempannel ul {
  padding: 0px;
  padding-left: 16px;
}
.itempannel li {
  color: rgba(0, 0, 0, 0.65);
  border-radius: 4px;
  width: 150px;
  height: 28px;
  line-height: 26px;
  /* padding-left: 8px; */
  border: 1px solid rgba(0, 0, 0, 0);
  list-style-type: none;
}
.itempannel li:hover {
  background: white;
  border: 1px solid #ced4d9;
  cursor: move;
}

.itempannel .pannel-type-icon {
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
}

/deep/ .el-collapse-item__header {
    display: flex;
    align-items: center;
    height: 48px;
    line-height: 20px;
    background-color: transparent;
    cursor: pointer;
    font-size: 15px;
    font-weight: 500;
    transition: border-bottom-color .3s;
    outline: none;
   
}


/deep/ .el-collapse-item__content {
  padding-bottom: 0px;
 
}



</style>
